import React from 'react';
import './css/Logo.css';

class Logo extends React.Component {
    render() {
        return (
            <div className={"logo-component"}>
                <div className={"logo-container"}>
                    <img src="/images/logo_white.png" alt="Starring You logo"/>
                </div>
            </div>
        );
    }
}

export default Logo;