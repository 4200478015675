import React, { useState } from 'react';
import './css/App.css';
import './css/Modal.css';
import Header from '../Header/Header';
import ThemeSelector from '../ThemeSelector/ThemeSelector';
import PosterStyler from '../PosterStyler/PosterStyler';
import Poster from '../Poster/Poster';
import Location from '../Location/Location';
import DateSelector from '../DateSelector/DateSelector';
import Description from '../Description/Description';
import AdvancedSettings from '../AdvancedSettings/AdvancedSettings';
import Order from '../Order/Order';
import Steps from '../Steps/Steps';
import VirtualSky from '../VirtualSky/VirtualSky';
import moment, { Moment } from 'moment-timezone';
import axios from 'axios';
import {isMobile, isTablet} from 'react-device-detect';
import PosterFormat from '../PosterFormat/PosterFormat';
moment.tz.setDefault('Europe/Amsterdam');

const CONFIG = require('../../config.json');
declare var $: any;

const App: React.FC = props => {

  var url = new URL(window.location.href);

  const [theme, setTheme] = useState(url.searchParams.get("theme") || 'black');
  const [format, setFormat] = useState(url.searchParams.get("format") || 'A2');
  const [showLogo, setShowLogo] = useState(url.searchParams.get("showLogo") ? (url.searchParams.get("showLogo") === 'true') : true);
  const [showTime, setShowTime] = useState(url.searchParams.get("showTime") ? (url.searchParams.get("showTime") === 'true') : false);
  const [description, setDescription] = useState(url.searchParams.get("description") || '');
  const [dateTime, setDateTime] = useState(url.searchParams.get("dateTime") ? moment(url.searchParams.get("dateTime") as string) : moment());
  const [location, setLocation] = useState(url.searchParams.get("location") || '');
  const [mapsLocation, setMapsLocation] = useState(url.searchParams.get("mapsLocation") || '');

  let latLngValue = ['40.730610', '73.935242'];
  let lat = url.searchParams.get("lat");
  let lng = url.searchParams.get("lng");
  
  if(lat && lng) {
    latLngValue = [lat, lng];
  }
  
  const [latLng, setLatLng] = useState(latLngValue);
    
  const [galactic, setGalactic] = useState(url.searchParams.get("galactic") ? (url.searchParams.get("galactic") === 'true') : true);
  const [constellations, setConstellations] = useState(url.searchParams.get("constellations") ? (url.searchParams.get("constellations") === 'true') : true);
  const [milkyway, setMilkyway] = useState(url.searchParams.get("milkyway") ? (url.searchParams.get("milkyway") === 'true') : false);
  const [background, setBackground] = useState(url.searchParams.get("background") ? (url.searchParams.get("background") === 'true') : false);
  const [moon, setMoon] = useState(url.searchParams.get("moon") ? (url.searchParams.get("moon") === 'true') : false);
  const [step, setStep] = useState(1);
  const [posterId, setPosterId] = useState(0);

  const handleTheme = (theme: string) => {
    setTheme(theme);
  };

  const handleFormat = (format: string) => {
    setFormat(format);
  };

  const handleShowTime = () => {
    setShowTime(!showTime);
  };

  const handleShowLogo = () => {
    setShowLogo(!showLogo);
  };

  const handleDescription = (description: string) => {
    setDescription(description);
  };

  const handleDate = (date: Moment) => {
    let newDate = moment();
    newDate.year(date.year());
    newDate.month(date.month());
    newDate.date(date.date());
    newDate.hour(dateTime.hour());
    newDate.minute(dateTime.minute());
    newDate.second(dateTime.second());

    console.log(newDate.toISOString());

    setDateTime(newDate);
  };

  const handleTime = (time: Moment) => {
    let newTime = moment();
    newTime.year(dateTime.year());
    newTime.month(dateTime.month());
    newTime.date(dateTime.date());
    newTime.hour(time.hour());
    newTime.minute(time.minute());
    newTime.second(time.second());

    setDateTime(newTime);
  };

  const handleLocation = (location?: string) => {
    setLocation(location || '');
  };

  const handleMapsLocation = (mapsLocation?: string) => {
    setMapsLocation(mapsLocation || '');
    setLocation(mapsLocation || '');
  };

  const handleLatLng = (latLng: string[]) => {
    setLatLng(latLng);
  };

  const handleGalactic = (galactic: boolean) => {
    setGalactic(galactic);
  };

  const handleConstellations = (constellations: boolean) => {
    setConstellations(constellations);
  };

  const handleMilkyway = (milkyway: boolean) => {
    setMilkyway(milkyway);
  };

  const handleBackground = (background: boolean) => {
    setBackground(background);
  };

  const handleMoon = (moon: boolean) => {
    setMoon(moon);
  };

  const handleStep = (step: number) => {
    let elm = document.getElementById("header");
    if(elm) {
      elm.scrollIntoView();
    }
    setStep(step);
    
  };

  const handleCheckout = () => {
    $('.loading_screen').show();
    axios
      .post(CONFIG.server_url, {
        theme: theme,
        format: format,
        showLogo: showLogo,
        showTime: showTime,
        description: description,
        dateTime: dateTime.toISOString(),
        mapsLocation: mapsLocation,
        location: location,
        latLng: latLng,
        galactic: galactic,
        constellations: constellations,
        milkyway: milkyway,
        background: background,
        moon: moon
      })
      .then(response => {
        setPosterId(response.data.message);
        let form = document.querySelector('#send-to-shop') as HTMLFormElement;
        form.submit()
      })
      .catch(error => {
        $('.loading_screen').hide();
      });
  };

  return (
    <div className={`App step_${step}`}>
      {(isMobile || isTablet) && (
        <div className="portrait_only">
          <span className="text">
            Draai je scherm om deze applicatie te gebruiken.
          </span>
        </div>
      )}
      <div className={'container auroral-container'}>
        <div className="auroral-northern" />
        <div className="auroral-stars" />
      </div>
      <div className="loading_screen">
        <div className="overlay"></div>
        <div className="loader_container">
          <div className="spinner"></div>
          <div className="text">Je poster wordt samengesteld, dit duurt ongeveer 15 seconden.</div>
        </div>
      </div>
      <div className={'wrapper'}>
        <div className={'container'}>
          <div className={'poster-settings'}>
            <Header />
            <ThemeSelector handleTheme={handleTheme} step={step} />
            <PosterStyler
              handleGalactic={handleGalactic}
              handleConstellations={handleConstellations}
              handleMilkyway={handleMilkyway}
              handleBackground={handleBackground}
              handleMoon={handleMoon}
              step={step}
            />
            {(isMobile || isTablet) && (
            <div className={'poster-container'}>
            <Poster
              theme={theme}
              date={dateTime}
              showLogo={showLogo}
              showTime={showTime}
              description={description}
              location={location}
              latLng={latLng}
              step={step}
              background={background}
              handleStep={handleStep}
            >
              <VirtualSky
                latLng={latLng}
                galactic={galactic}
                constellations={constellations}
                theme={theme}
                milkyway={milkyway}
                date={dateTime}
                moon={moon}
              />
            </Poster>
          </div>
            )}
            <PosterFormat
              handleFormat={handleFormat}
              format={format}
              step={step}
            ></PosterFormat>
            <Location
              handleMapsLocation={handleMapsLocation}
              handleLatLng={handleLatLng}
              step={step}
            />
            <DateSelector handleDate={handleDate} date={dateTime} step={step} />
            <Description handleDescription={handleDescription} step={step} />
            <AdvancedSettings
              handleShowLogo={handleShowLogo}
              handleShowTime={handleShowTime}
              handleTime={handleTime}
              handleLocation={handleLocation}
              time={dateTime}
              location={location}
              step={step}
            />
            <Order step={step} handleCheckout={handleCheckout} />
          </div>
          {(!isMobile && !isTablet) && (
          <div className={'poster-container'}>
            <Poster
              theme={theme}
              date={dateTime}
              showLogo={showLogo}
              showTime={showTime}
              description={description}
              location={location}
              latLng={latLng}
              step={step}
              background={background}
              handleStep={handleStep}
            >
              <VirtualSky
                latLng={latLng}
                galactic={galactic}
                constellations={constellations}
                theme={theme}
                milkyway={milkyway}
                date={dateTime}
                moon={moon}
              />
            </Poster>
          </div>
          )}
          <Order step={step} handleCheckout={handleCheckout} />
          <Steps step={step} handleStep={handleStep} />
        </div>
      </div>
      <form
        id="send-to-shop"
        action={`${CONFIG.shop_url}?wpb_custom_cart=true`}
        method="POST"
      >
        <input type="hidden" name="poster" value={posterId} />
      </form>
    </div>
  );
};

export default App;
