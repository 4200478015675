import React, { useState } from 'react';
import './css/PosterStyler.css';
import StyleRow from './StyleRow/StyleRow';

declare var $: any;
declare var MicroModal: any;

interface IPropsPosterStyler {
  handleGalactic(value: boolean): any;
  handleConstellations(value: boolean): any;
  handleMilkyway(value: boolean): any;
  handleBackground(value: boolean): any;
  handleMoon(value: boolean): any;
  step: number;
}

const PosterStyler: React.FC<IPropsPosterStyler> = props => {
  const [toggled, setToggled] = useState(true);

  const handleBackground = (e: any) => {
    return props.handleBackground($(e.target).prop('checked'));
  };

  const handleConstellations = (e: any) => {
    props.handleConstellations($(e.target).prop('checked'));
  };

  const handleMilkyway = (e: any) => {
    props.handleMilkyway($(e.target).prop('checked'));
  };

  const handleMoon = (e: any) => {
    props.handleMoon($(e.target).prop('checked'));
  };

  const handleGalactic = (e: any) => {
    props.handleGalactic($(e.target).prop('checked'));
  };

  const handleToggler = (e: any) => {
    setToggled(!toggled);
  };

  return (
    <div id={'poster-styler'} className={`step_${props.step}`}>
      <div className={'container'}>
        <div
          className={`head ${toggled ? 'open' : 'closed'} `}
          onClick={handleToggler}
        >
          Stijl je persoonlijke poster
          <span className={'toggler'} />
        </div>
        <div className={'container'}>
          <StyleRow
            name={'Witte achtergrond'}
            handleToggle={handleBackground}
          />
          <StyleRow
            name={'Sterrenbeelden'}
            handleToggle={handleConstellations}
            checked={true}
          />
          <StyleRow name={'Melkweg'} handleToggle={handleMilkyway} />
          <StyleRow name={'Maan'} handleToggle={handleMoon} />
          <StyleRow
            name={'Raster'}
            handleToggle={handleGalactic}
            checked={true}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PosterStyler);
