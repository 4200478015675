import React from 'react';
import './css/virtualsky.css';
import { Moment } from 'moment-timezone';
import { isMobileOnly } from 'react-device-detect';

declare var Celestial: any;
declare var $: any;

class VirtualSky extends React.PureComponent<
  {
    latLng: string[];
    galactic: boolean;
    constellations: boolean;
    theme: string;
    milkyway: boolean;
    date: Moment;
    moon: boolean;
  },
  {}
> {
  config = {};
  ctx: any;

  getBackgroundColor() {
    switch (this.props.theme) {
      case 'black':
        return '#000';
      case 'purple':
        return 'rgb(23,30,64)';
      case 'blue':
        return 'rgb(0,60,87)';
      case 'green':
        return 'rgb(31,0,47)';
      case 'pink':
        return 'rgb(45,45,45)';
      default:
        break;
    }
  }

  getLat() {
    return parseFloat(this.props.latLng[0]);
  }

  getLng() {
    return parseFloat(this.props.latLng[1]);
  }

  render() {
    return (
      <div id="starmap">
        <div className={'circle'} />
        <div id="celestial-form" />
      </div>
    );
  }

  getConfig() {
    return {
      width: 0,
      projection: 'airy',
      interactive: false,
      form: true,
      location: true,
      controls: false,
      container: 'starmap',
      datapath: '/data/',
      stars: {
        show: true,
        colors: false,
        style: { fill: '#fff', opacity: 1 },
        names: false,
        size: isMobileOnly ? 3 : 8
      },
      dsos: { show: false },
      planets: {
        show: this.props.moon,
        // List of all objects to show
        which: [
          'sol',
          'mer',
          'ven',
          'ter',
          'lun',
          'mar',
          'jup',
          'sat',
          'ura',
          'nep'
        ],
        // Font styles for planetary symbols
        style: {
          fill: '#00ccff',
          font: "bold 17px 'Lucida Sans Unicode', Consolas, sans-serif",
          align: 'center',
          baseline: 'middle'
        },
        symbols: {
          // Character and color for each symbol in 'which', simple circle \u25cf
          sol: { symbol: '\u2609', fill: 'rgba(0,0,0,0)' },
          mer: { symbol: '\u263f', fill: 'rgba(0,0,0,0)' },
          ven: { symbol: '\u2640', fill: 'rgba(0,0,0,0)' },
          ter: { symbol: '\u2295', fill: 'rgba(0,0,0,0)' },
          lun: { symbol: '\u25cf', fill: '#ffffff' }, // overridden by generated cresent
          mar: { symbol: '\u2642', fill: 'rgba(0,0,0,0)' },
          cer: { symbol: '\u26b3', fill: 'rgba(0,0,0,0)' },
          ves: { symbol: '\u26b6', fill: 'rgba(0,0,0,0)' },
          jup: { symbol: '\u2643', fill: 'rgba(0,0,0,0)' },
          sat: { symbol: '\u2644', fill: 'rgba(0,0,0,0)' },
          ura: { symbol: '\u2645', fill: 'rgba(0,0,0,0)' },
          nep: { symbol: '\u2646', fill: 'rgba(0,0,0,0)' },
          plu: { symbol: '\u2647', fill: 'rgba(0,0,0,0)' },
          eri: { symbol: '\u25cf', fill: 'rgba(0,0,0,0)' }
        }
      },
      constellations: {
        show: this.props.constellations,
        names: false,
        desig: false,
        lines: true,
        linestyle: { stroke: '#fff', width: isMobileOnly ? 0.6 : 0.8, opacity: isMobileOnly ? 0.4 : 0.7, dash: [0] },
        bounds: false
      },
      mw: {
        show: this.props.milkyway,
        style: { fill: '#13E87E', opacity: 0.15 }
      },
      lines: {
        graticule: {
          show: this.props.galactic,
          stroke: '#eee',
          width: isMobileOnly ? 0.5 : 0.8,
          opacity: isMobileOnly ? 0.4 : 0.7
        },
        equatorial: { show: false },
        ecliptic: { show: false },
        galactic: { show: false },
        supergalactic: { show: false }
      },
      background: {
        fill: this.getBackgroundColor(),
        opacity: 1,
        stroke: '#ffffff', // Outline
        width: isMobileOnly ? 1 : 3
      },
      horizon: { show: false }
    };
  }

  drawCanvas = () => {
    if (!this.ctx) {
      let canvas = document.querySelector(
        '#starmap canvas'
      ) as HTMLCanvasElement;

      this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      // lets get the resolution of our device.
      var pixelRatio = window.devicePixelRatio > 1 ? 5 : 2;

      // Adjust canvas size to pixel ratio
      //canvas.style.width = canvas.width / pixelRatio + 'px';
      //canvas.style.height = canvas.height / pixelRatio + 'px';
      //canvas.width *= pixelRatio;
      //canvas.height *= pixelRatio;
      this.ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    }
    Celestial.apply({});
  };

  alterD3YearSelector() {
    let year = new Date().getFullYear() + 50;
    let yearElement = $('#yr');
    yearElement.children().remove();
    for (let i = 1900; i < year + 1; i++) {
      yearElement.prepend(
        $('<option />', {
          value: i,
          text: i
        })
      );
    }
    yearElement.find('option[value=' + year + ']').attr('selected', true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.drawCanvas);
  }

  componentDidMount() {
    Celestial.display(this.getConfig());
    this.drawCanvas();
    this.alterD3YearSelector();

    $('#mon').val(this.props.date.month());
    $('#yr').val(this.props.date.year());

    $('#hr').val(this.props.date.hour());
    $('#min').val(this.props.date.minute());
    $('#tz').val(this.props.date.utcOffset());

    $('#lat').val(this.getLat());
    $('#lon').val(this.getLng());

    $('#left').click();
    $('#right').click();

    let date = this.props.date.format('YYYY-MM-DD');
    $('#' + date).click();

    $('#day-right').click();
    $('#day-left').click();

    Celestial.apply(this.getConfig());
    let timeout = 0;
    let drawCanvas = this.drawCanvas;
    window.addEventListener('resize', function(e) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        drawCanvas();
      }, 500);
    });
    window.addEventListener('orientationchange', function(e) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        drawCanvas();
      }, 500);
    });

    window.dispatchEvent(new Event('resize'));
  }

  componentDidUpdate() {
    let config = {
      lines: {
        graticule: {
          show: this.props.galactic,
          stroke: '#eee',
          width: isMobileOnly ? 0.5 : 0.8,
          opacity: isMobileOnly ? 0.4 : 0.7
        },
      },
      planets: {
        show: this.props.moon,
        // List of all objects to show
        which: [
          'sol',
          'mer',
          'ven',
          'ter',
          'lun',
          'mar',
          'jup',
          'sat',
          'ura',
          'nep'
        ],
        // Font styles for planetary symbols
        style: {
          fill: '#00ccff',
          font: "bold 17px 'Lucida Sans Unicode', Consolas, sans-serif",
          align: 'center',
          baseline: 'middle'
        },
        symbols: {
          // Character and color for each symbol in 'which', simple circle \u25cf
          sol: { symbol: '\u2609', fill: 'rgba(0,0,0,0)' },
          mer: { symbol: '\u263f', fill: 'rgba(0,0,0,0)' },
          ven: { symbol: '\u2640', fill: 'rgba(0,0,0,0)' },
          ter: { symbol: '\u2295', fill: 'rgba(0,0,0,0)' },
          lun: { symbol: '\u25cf', fill: '#ffffff' }, // overridden by generated cresent
          mar: { symbol: '\u2642', fill: 'rgba(0,0,0,0)' },
          cer: { symbol: '\u26b3', fill: 'rgba(0,0,0,0)' },
          ves: { symbol: '\u26b6', fill: 'rgba(0,0,0,0)' },
          jup: { symbol: '\u2643', fill: 'rgba(0,0,0,0)' },
          sat: { symbol: '\u2644', fill: 'rgba(0,0,0,0)' },
          ura: { symbol: '\u2645', fill: 'rgba(0,0,0,0)' },
          nep: { symbol: '\u2646', fill: 'rgba(0,0,0,0)' },
          plu: { symbol: '\u2647', fill: 'rgba(0,0,0,0)' },
          eri: { symbol: '\u25cf', fill: 'rgba(0,0,0,0)' }
        }
      },
      constellations: {
        show: this.props.constellations,
        names: false,
        desig: false,
        lines: true,
        linestyle: { stroke: '#fff', width: isMobileOnly ? 0.6 : 0.8, opacity: isMobileOnly ? 0.4 : 0.7, dash: [0] },
        bounds: false
      },
      mw: {
        show: this.props.milkyway,
        style: { fill: '#13E87E', opacity: 0.15 }
      },
      background: {
        fill: this.getBackgroundColor(),
        opacity: 1,
        stroke: '#ffffff', // Outline
        width: isMobileOnly ? 1 : 3
      }
    };

    this.alterD3YearSelector();

    $('#mon').val(this.props.date.month());
    $('#yr').val(this.props.date.year());

    $('#hr').val(this.props.date.hour());
    $('#min').val(this.props.date.minute());
    $('#tz').val(this.props.date.utcOffset());

    $('#lat').val(this.getLat());
    $('#lon').val(this.getLng());

    $('#left').click();
    $('#right').click();

    let date = this.props.date.format('YYYY-MM-DD');
    $('#' + date).click();

    $('#day-right').click();
    $('#day-left').click();

    Celestial.apply(config);
  }
}

export default VirtualSky;
