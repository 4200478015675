import React from 'react';
import {Toggle} from "react-toggle-component";

interface IStyleRow {
    name: string;
    handleToggle: any;
    checked?: boolean;
}

class StyleRow extends React.Component<IStyleRow> {
    render() {
        return (
            <div className={"style-row"}>
            {this.props.name}
            <Toggle
            rightBackgroundColor="#171E40"
            rightKnobColor="#FFF"
            leftBackgroundColor="#939393"
            leftKnobColor="#FFF"
            rightBorderColor="transparent"
            leftBorderColor="transparent"
            checked={this.props.checked ? true : false}
            name={this.props.name} 
            onToggle={this.props.handleToggle}></Toggle>
            </div>
        );
    }
}

export default StyleRow;
