import React, { useState } from 'react';
import './css/AdvancedSettings.css';
import { Toggle } from 'react-toggle-component';
import { Moment } from 'moment-timezone';

interface IAdvancedSettingsProps {
  handleShowLogo(): any;
  handleShowTime(): any;
  handleTime(time: Moment): any;
  handleLocation(location: string): any;
  time: Moment;
  location: string;
  step: number;
}

const AdvancedSettings: React.FC<IAdvancedSettingsProps> = props => {
  const [toggled, setToggled] = useState(false);

  const handleToggleTime = (e: any) => {
    props.handleShowTime();
  };

  const handleToggle = (e: any) => {
    props.handleShowLogo();
  };

  const handleToggler = (e: any) => {
    setToggled(!toggled);
  };

  const handleHour = (e: any) => {
    props.time.hour(e.target.value);
    props.handleTime(props.time);
  };

  const handleMinutes = (e: any) => {
    props.time.minute(e.target.value);
    props.handleTime(props.time);
  };

  const setHourOptions = () => {
    let options: any[] = [];
    for (var i = 0; i <= 23; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const setMinutesOptions = () => {
    let options: any[] = [];
    for (var i = 0; i <= 59; i++) {
      let minutes = '' + i;
      if (i < 10) {
        minutes = '0' + i;
      }
      options.push(
        <option key={minutes} value={i}>
          {minutes}
        </option>
      );
    }
    return options;
  };

  return (
    <div className={`advanced-settings-component step_${props.step}`}>
      <div className={'container'}>
        <div
          className={`head ${toggled ? 'open' : 'closed'}`}
          onClick={handleToggler}
        >
          Meer opties
          <span className={'toggle'} />
        </div>
        <div className={'description-container'}>
          <div className={'title'}>Omschrijf waar je was</div>
          <div className={'input-container'}>
            <input
              id="poster_location_description"
              name="description"
              defaultValue={props.location}
              placeholder="Bijv. Bij onze lieve moeder"
              onChange={e => props.handleLocation(e.target.value)}
            />
          </div>
        </div>
        <div className={'options-container'}>
          <div className={'time-container'}>
            <div className={'title'}>Hoe laat was het?</div>
            <div className={'input-container'}>
              <select
                id="poster_hour"
                onChange={handleHour}
                value={props.time.hour()}
              >
                {setHourOptions()}
              </select>
              <span className={'sep'}>:</span>
              <select
                id="poster_minute"
                onChange={handleMinutes}
                value={props.time.minute()}
              >
                {setMinutesOptions()}
              </select>
            </div>
          </div>
          <div className={'starring-you-container'}>
            <div className={'title'}>Starring You logo</div>
            <div className={'input-container'}>
              <Toggle
                rightBackgroundColor="#171E40"
                rightKnobColor="#FFF"
                leftBackgroundColor="#939393"
                leftKnobColor="#FFF"
                rightBorderColor="transparent"
                leftBorderColor="transparent"
                checked={true}
                name={'starring_you_logo'}
                height={'32px'}
                width={'64px'}
                knobWidth={'20px'}
                knobHeight={'20px'}
                onToggle={handleToggle}
              />
            </div>
          </div>
          <div className={'starring-you-container'}>
            <div className={'title'}>Tijd weergeven?</div>
            <div className={'input-container'}>
              <Toggle
                rightBackgroundColor="#171E40"
                rightKnobColor="#FFF"
                leftBackgroundColor="#939393"
                leftKnobColor="#FFF"
                rightBorderColor="transparent"
                leftBorderColor="transparent"
                checked={false}
                name={'starring_you_disable_time'}
                height={'32px'}
                width={'64px'}
                knobWidth={'20px'}
                knobHeight={'20px'}
                onToggle={handleToggleTime}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdvancedSettings);
