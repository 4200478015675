import React from 'react';
import './css/DateSelector.css';
import { Moment } from 'moment-timezone';

interface IDateSelectorProps {
  handleDate(value: Moment): any;
  date: Moment;
  step: number;
}

const DateSelector: React.FC<IDateSelectorProps> = props => {
  const months = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
  ];

  const handleDay = (e: any) => {
    props.date.date(e.target.value);
    props.handleDate(props.date);
  };

  const handleMonth = (e: any) => {
    props.date.month(parseInt(e.target.value));
    props.handleDate(props.date);
  };

  const handleYear = (e: any) => {
    props.date.year(e.target.value);
    props.handleDate(props.date);
  };
  const setYearOptions = () => {
    let options: any[] = [];
    const years = new Date().getFullYear() + 50;
    for (var i = years; i >= 1900; i--) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const setMonthOptions = () => {
    let options: any[] = [];
    months.forEach((element, index) => {
      options.push(
        <option key={index} value={index}>
          {element}
        </option>
      );
    });
    return options;
  };

  const setDayOptions = () => {
    let options: any[] = [];
    for (var i = 1; i <= 31; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return (
    <div className={`date-selector-component step_${props.step}`}>
      <div className={'container'}>
        <div className={'head'}>Voor welke datum wil je de sterren zien?</div>
        <div className={'container'}>
          <select
            id="poster_year"
            name="year"
            className={'year'}
            value={props.date.year()}
            onChange={handleYear}
          >
            {setYearOptions()}
          </select>
          <select
            id="poster_month"
            name="month"
            className={'month'}
            value={props.date.month()}
            onChange={handleMonth}
          >
            {setMonthOptions()}
          </select>
          <select
            id="poster_day"
            name="day"
            className={'day'}
            value={props.date.date()}
            onChange={handleDay}
          >
            {setDayOptions()}
          </select>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DateSelector);
