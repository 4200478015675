import React, { useState } from 'react';
import './css/Order.css';

const CONFIG = require('../../config.json');

declare var $: any;

interface IPropsOrder {
  step: number;
  handleCheckout(): any;
}

const Order: React.FC<IPropsOrder> = props => {
  const [dataImage, setDataImage] = useState('');

  const generatePoster = () => {
    let canvas = $('#starmap > canvas')[0] as HTMLCanvasElement;
    setDataImage(canvas.toDataURL());
  };

  return (
    <div className={`order-component step_${props.step}`}>
      <button type="button" id="handleCheckout" onClick={generatePoster} />
      <div className={'container'}>
        <div className={'button-container'}>
          <button
            type="button"
            className={'btn order'}
            onClick={props.handleCheckout}
          >
            Afrekenen
          </button>
          <form
            id="checkout-form"
            action={`${CONFIG.shop_url}?wpb_custom_cart=true`}
            method="POST"
          >
            <input type="hidden" id="poster_base64" value={dataImage} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Order);
