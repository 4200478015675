import React from 'react';
import './css/ThemeSelector.css';
import Theme from './Theme/Theme';

interface IThemeSelectorProps {
    handleTheme(value: string): any;
    step: number;
}
const ThemeSelector: React.FC<IThemeSelectorProps> = (props) => {
    const themes = ['black', 'purple', 'blue', 'green', 'pink'];

    const handleThemeSelect = (key: number) => {
        props.handleTheme(themes[key]);
    }

    const createPalettes = () => {
        let palettes: any[] = [];
        themes.forEach((element, index) => {
            palettes.push(<Theme key={index} background={""+element+""} onClick={() => handleThemeSelect(index)}></Theme>);
        });
        return palettes;
    }
        return (
            <div id={"theme-selector"} className={`step_${props.step}`}>
                <div className={"container"}>
                    {createPalettes()}
                </div>
            </div>
        );
}

export default ThemeSelector;