import React from 'react';
import './css/Poster.css';
import { Moment } from 'moment-timezone';

interface IPosterProps {
  theme: string;
  date: Moment;
  showLogo: boolean;
  showTime: boolean;
  description: string;
  location: string;
  latLng: string[];
  background: boolean;
  step: number;
  handleStep(step: number): any;
}

const Poster: React.FC<IPosterProps> = props => {
  const logo = props.background
    ? `/images/logo_${props.theme}.png`
    : '/images/logo_white.png';

  return (
    <div id="poster" className={`step_${props.step}`}>
      <div
        className={`container ${props.theme} ${
          props.background ? 'invert' : ''
        }`}
      >
        <div className={'inner-border'}>
          {props.children}

          <div className={'text-container'}>
            <div className={'description'}>{props.description}</div>
          </div>
          <div className={'poster-details'}>
            <div className={'date'}>
              De sterrenhemel van {props.date.format('DD-MM-YYYY')}
              {props.showTime ? ' om ' + props.date.format('HH:mm') : ''}
            </div>
            <div className={'location'}>{props.location}</div>
            <div className={'latlng'}>
              {props.latLng[0] ? props.latLng[0] + '° lat, ' : ''}{' '}
              {props.latLng[1] ? props.latLng[1] + '° lng' : ''}
            </div>
          </div>
          <div className={`logo ${props.showLogo ? 'show' : 'hide'}`}>
            <div className={'logo-component'}>
              <div className={'logo-container'}>
                <img alt="Starringyou logo" src={logo} />
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className={'button-container'}>
        <button
          className={'btn btn-primary'}
          onClick={() => {
            props.handleStep(2);
          }}
        >
          Volgende stap <span className={'arrow-right'} />
        </button>
      </div>
    </div>
  );
};

export default Poster;
