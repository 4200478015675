import React from 'react';

interface ITheme {
    background: string;
    onClick: any;
}

class Theme extends React.Component<ITheme> {
    render() {
        return (
            <button type="button" className={`theme ${this.props.background}`} onClick={this.props.onClick}>
            </button>
        );
    }
}

export default Theme;