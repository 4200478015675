import React from 'react';
import './css/Steps.css';

interface IPropsSteps {
    step: number;
    handleStep(step: number): any;
}

const Steps: React.FC<IPropsSteps> = (props) => {
        return(
            <div className={`steps-component step_${props.step}`}>
                <div className={"container"}>
                    <div className={"nav_1"}>
                        <button className={"btn btn-primary"} onClick={() => { props.handleStep(3) } }>
                            Bekijk je poster <span className={"arrow-right"}></span>
                        </button>
                        <button className={"btn btn-white"} onClick={() => { props.handleStep(1) } }>
                            <span className={"arrow-left"}></span>Terug 
                        </button>
                    </div>
                    <div className={"nav_2"}>
                        <div className={"head"}>Toch nog iets veranderen?</div>
                        <div className={"steps-container"}>
                            <div className={"step"} onClick={ () => { props.handleStep(1) } }>
                                <div className={"number"}>1</div>
                                <div className={"description"}>Kleuren & Sterren</div>
                            </div>
                            <div className={"step"} onClick={ () => { props.handleStep(2) } }>
                                <div className={"number"}>2</div>
                                <div className={"description"}>Over het moment</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Steps;
