import React, { useState } from 'react';
import './css/PosterFormat.css';

interface IPropsPosterFormat {
  handleFormat(format: string): any;
  format: string;
  step: number;
}

const PosterFormat: React.FC<IPropsPosterFormat> = props => {
  const [toggled, setToggled] = useState(true);

  const handleToggler = () => {
    setToggled(!toggled);
  };

  const handleFormat = (e: any) => {
    props.handleFormat(e.target.value);
  };

  return (
    <div id={'poster-format'} className={`step_${props.step}`}>
      <div className={'container'}>
        <div
          className={`head ${toggled ? 'open' : 'closed'} `}
          onClick={handleToggler}
        >
          Kies het formaat van je poster
          <span className={'toggler'} />
        </div>
        <div className={'container'}>
          <div className={'radio-label'}>
            <input
              id="format_a3"
              type="radio"
              name="poster_size"
              value="A3"
              checked={props.format === 'A3'}
              onChange={handleFormat}
            />
            <label htmlFor="format_a3">A3 (29.7 x 42 cm) (€29,99)</label>
            <div
              className={`format-description ${
                props.format === 'A3' ? 'selected' : ''
              }`}
            >
              Voor dit formaat is er een fotolijst en zijn er posterlatjes
              beschikbaar.
            </div>
          </div>
          <div className={'radio-label'}>
            <input
              id="format_a2"
              type="radio"
              name="poster_size"
              value="A2"
              checked={props.format === 'A2'}
              onChange={handleFormat}
            />
            <label htmlFor="format_a2">A2 (42 x 59.4 cm) (€39,99) <small className={"green-label"}> Geen verzendkosten</small></label>
            <div
              className={`format-description ${
                props.format === 'A2' ? 'selected' : ''
              }`}
            >
              Voor dit formaat is er een fotolijst en zijn er posterlatjes
              beschikbaar.
            </div>
          </div>
          <div className={'radio-label'}>
            <input
              id="format_a1"
              type="radio"
              name="poster_size"
              value="A1"
              checked={props.format === 'A1'}
              onChange={handleFormat}
            />
            <label htmlFor="format_a1">A1 (59.4 x 84.1 cm) (€44,99) <small className={"green-label"}> Geen verzendkosten</small></label>
            <div
              className={`format-description ${
                props.format === 'A1' ? 'selected' : ''
              }`}
            >
              Voor dit formaat hebben we nog geen fotolijst beschikbaar.
            </div>
          </div>
          <div className={'radio-label'}>
            <input
              id="format_a0"
              type="radio"
              name="poster_size"
              value="A0"
              checked={props.format === 'A0'}
              onChange={handleFormat}
            />
            <label htmlFor="format_a0">A0 (84.1 x 118.9 cm) (€49,99) <small className={"green-label"}> Geen verzendkosten</small></label>
            <div
              className={`format-description ${
                props.format === 'A0' ? 'selected' : ''
              }`}
            >
              Voor dit formaat hebben we nog geen fotolijst beschikbaar.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PosterFormat);
