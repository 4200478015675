import React from 'react';
//@ts-ignore
import Autocomplete from 'react-google-autocomplete';
import './css/Location.css';

interface ILocationProps {
  handleMapsLocation(mapslocation?: string): any;
  handleLatLng: any;
  step: number;
}

const Location: React.FC<ILocationProps> = props => {
  const onPlaceSelected = (place: any) => {
    const latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    props.handleMapsLocation(
      place.formatted_address ? place.formatted_address : ''
    );
    props.handleLatLng([
      parseFloat(latValue).toFixed(8),
      parseFloat(lngValue).toFixed(8)
    ]);
  };

  return (
    <div className={`location-component step_${props.step}`}>
      <div className={'container'}>
        <div className={'head'}>Kies een locatie</div>
        <div className={'container'}>
          <i className={'world-icon'} />
          <Autocomplete
            onPlaceSelected={onPlaceSelected}
            types={['geocode']}
            placeholder={'Kies je locatie'}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Location);
