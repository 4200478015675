import React from 'react';
import Logo from './Logo/Logo';
import './css/Header.css';

class Header extends React.PureComponent {
  render() {
    return (
      <header id="header">
        <a href="/">
          <Logo />
        </a>
        <div className={'title'}>
          <span className={'head'}>Je meest waardevolle moment </span>
          vastgelegd in de sterren
        </div>
      </header>
    );
  }
}

export default Header;
