import React, { useEffect, useState } from 'react';
import './css/Description.css';

interface IDescriptionProps {
  handleDescription: any;
  step: number;
}

const Description: React.FC<IDescriptionProps> = props => {
  const [toggled, setToggled] = useState(true);
  const [totalLines, setTotalLines] = useState(1);
  const max_lines = 8;

  let timeout = 0;

  const handleDescription = (e: any) => {
    let value = e.target.value.replace( /[^a-zA-Z0-9éëêèęėēúüûùūíïìîįīóöôòõœøōáäâàæãåāj́çñń☆★♡\t\n .,~/<>?;:"'`!@#$€%^&*()\[\]{}_+=|\\-]/gm, '');
    var text = value.replace(/\s+$/g, '');
    text = text.replace(/(.{58})/g, '$1\n');
    var splittedText = text.split('\n');
    setTotalLines(splittedText.length);
    if (splittedText.length > max_lines) {
      let newValue = '';
      for (var i = 0; i < max_lines; i++) {
        if (splittedText[i]) {
          newValue += splittedText[i] + '\n';
        }
      }

      value = newValue;
    }

    let textarea = document.querySelector(
      '#poster_description'
    ) as HTMLTextAreaElement;
    textarea.value = value;

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      props.handleDescription(value);
    }, 500);
  };

  const handleToggler = (e: any) => {
    setToggled(!toggled);
  };

  const addEmojiToText = (value: string) => {
    let textarea = document.querySelector(
      '#poster_description'
    ) as HTMLTextAreaElement;
    textarea.value += value;
    
    props.handleDescription(textarea.value);
  };

  return (
    <div className={`description-component step_${props.step}`}>
      <div className={'container'}>
        <div
          className={`head ${toggled ? 'open' : 'closed'}`}
          onClick={handleToggler}
        >
          Schrijf een bericht op je poster
          <span className={'toggle'} />
        </div>
        <div className={'container'}>
          <textarea
            id="poster_description"
            name="description"
            onChange={handleDescription}
          />
          <div className={'add-emoji-bar'}>
            <p>Klik om toe te voegen:</p> <span onClick={() =>addEmojiToText('★')}>★</span><span onClick={() =>addEmojiToText('☆')}>☆</span><span onClick={() =>addEmojiToText('♡')}>♡</span>  
          </div>
        </div>
        <div
          className={`amount_lines ${totalLines > max_lines ? 'error' : ''}`}
        >
          
          
          {totalLines} / {max_lines} lines
        </div>
      </div>
    </div>
  );
};

export default React.memo(Description);
